<template>
  <div class="mt-5">
    <v-data-table
      :headers="headers"
      :items="rolesData">
      <!-- <template #[`item.No`]="{ item }">
        {{ index + 1 }}
      </template> -->
      <template #[`item.createTime`]="{ item }">
        {{ item.createTime | formatDate }}
      </template>
      <template #[`item.activated`]="{ item }">
        <v-icon small
                :style="{ color: item.style.color }">
          mdi-toggle-switch
        </v-icon>
      </template>
      <template #[`item.action`]="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn icon
                   @click="getUserRole(item)"
                   v-on="on">
              <v-icon small
                      color="grey lighten-1">
                mdi-square-edit-outline
              </v-icon>
            </v-btn>
          </template>
          <span>Edit</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-model="openFormUpdateRole"
      persistent
      max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Update Role</span>
        </v-card-title>
        <v-divider />
        <v-card-text class="card-text">
          <v-container>
            <v-text-field
              v-model="role.name"
              label="Role name"
              outlined
              required />

            <h3>Role Condition</h3>
            <v-row>
              <v-col class="roleTable"
                     cols="4">
                Application Name
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                Create
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                Edit
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                View
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                Cancle
              </v-col>
            </v-row>
            <v-row class="heigth: 100px">
              <v-col class="roleTable"
                     cols="4">
                Job Management
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="job.create"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="job.edit"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="job.view"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="job.cancle"
                            dense />
              </v-col>
            </v-row>

            <v-row class="heigth: 100px">
              <v-col class="roleTable"
                     cols="4">
                Customer
              </v-col>
              <v-col class="roleTable"
                     cols="2">
              </v-col>
              <v-col class="roleTable"
                     cols="2">
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="customer.view"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
              </v-col>
            </v-row>

            <v-row class="heigth: 100px">
              <v-col class="roleTable"
                     cols="4">
                Report
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="report.create"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="report.view"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
              </v-col>
            </v-row>

            <v-row class="heigth: 100px">
              <v-col class="roleTable"
                     cols="4">
                Administrators
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="admin.create"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="admin.edit"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="admin.view"
                            dense />
              </v-col>
              <v-col class="roleTable"
                     cols="2">
                <v-checkbox v-model="role.scopes"
                            value="admin.cancle"
                            dense />
              </v-col>
            </v-row>

            <v-checkbox
              v-model="role.activated"
              class="checkboxMarginSet"
              label="Active" />
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            depressed
            color="accent"
            @click.prevent="updateUserRole">
            update
          </v-btn>
          <v-btn class="ml-5"
                 depressed
                 color="error"
                 @click="openFormUpdateRole = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'RoleList',
  props: {
    rolesData: {
      type: Array,
    }
  },
  data() {
    return {
      openFormUpdateRole: false,
      editedIndex: -1,
      headers: [
        // {
        //   text: 'No.',
        //   align: 'center',
        //   sortable: false,
        //   value: 'No'
        // },
        {
          text: 'Department Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        {
          text: 'Create Date',
          align: 'center',
          sortable: true,
          value: 'createTime'
        },
        {
          align: 'start',
          sortable: true,
          value: 'action'
        },
      ],
      role: {
        _id: '',
        name: '',
        activated: '',
        scrope: [],
      }
    };
  },

  computed: {
    rolesList() {
      return this.rolesData.map((item, index) => ({
        No: index + 1,
        ...item,
        style: {
          color: item.activated === true ? '#00C853' : '#C62828'
        }
      }));
    },
  },

  methods: {
    async getUserRole(item) {
      this.editedIndex = this.rolesData.indexOf(item);

      this.role = { ...item };
      this.openFormUpdateRole = true;
    },

    async updateUserRole() {
      await this.$store.dispatch('userRole/updateUserRole', this.role);
      this.openFormUpdateRole = false;
    }
  }
};
</script>

<style>
</style>
